
import { Axios } from '../../util/index'
import { NotificationManager } from 'react-notifications';

//-----------get user list with paginated data--------------------------// 
export const getGalleryList = (params, setisLoader) => {
    setisLoader(true)
    return dispatch => {
        Axios.get(`/gallery/paginated?page=${params.page}&limit=${params.perPage}&search=${params.search}`).then(response => {
            dispatch({
                type: 'GET_DATA_INFORMATION_GALLERY',
                allData: response.data.data.total,
                data: response.data.data.docs,
                totalPages: response.data.data.pages,
                params
            })
        })
            .catch(error => {
                dispatch({
                    type: 'GET_DATA_INFORMATION_GALLERY',
                    allData: 0,
                    data: [],
                    totalPages: 0,
                    params
                })
            }).finally(() => {
                setisLoader(false)
            })
    }
}

//-----------------create new information--------------------------//
export const createNew = (data, props, setisLoader, reset, setShowPreview,setShowThumbnailPreview) => {
    return (dispatch, getStore) => {
        Axios.post(`/gallery`, {
            title: data.title,
            url: data.url,
            thumbnail: data.thumbnail,
            category_id : data.category_id ,
            type: data.type,
            language: data.language,
        })
            .then(response => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                }
                else {
                    props.onMasterSave();
                    reset()
                    setShowPreview(false)
                    setShowThumbnailPreview(false)
                    NotificationManager.success(response.data.message);
                    return dispatch => {
                        dispatch(
                            {
                                type: 'CREATE_INFORMATION_GALLERY',
                                data: []
                            }
                        )
                    }
                }
            })

            .then(() => dispatch(getGalleryList(getStore().formInfoReducer.params, setisLoader)))
            .catch(function (error) {
                NotificationManager.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !');
            })

    }
}

// ------------------update information------------------------//
export const update = (data, props, setisLoader) => {
    console.log("action",data)
    return (dispatch, getStore) => {
        Axios.put(`/gallery/${props.preloadValue.detail._id}`, {
            title: data.title,
            url: data.url,
            category_id : data.category_id ,
            thumbnail: data.thumbnail,
            type: data.type,
            language: data.language,
        }).then(response => {
            if (response.data.status === false) {
                NotificationManager.error(response.data.message);

            } else {
                NotificationManager.success(response.data.message);
                props.onMasterSave();
                return dispatch => {
                    dispatch(
                        {
                            type: 'UPDATE_INFORMATION_GALLERY',
                            data
                        }
                    )
                }
            }
        })
            .then(() => dispatch(getGalleryList(getStore().formInfoReducer.params, setisLoader)))
            .catch(function (error) {
                NotificationManager.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !');

            })


    }
}


