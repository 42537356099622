/*
 * App Routes
 *
 */
import React from "react";
import { Route, Redirect } from "react-router-dom";

// app default layout
import HulkAppLayout from "components/HulkAppLayout";

// router service
import routerService from "../routes/Routes";
import { Typography } from "@material-ui/core";

function DefaultLayout(props) {
  const { match } = props;
  return (
    <HulkAppLayout>
      <div className="flex-container">
        {routerService.map((route, key) => (
          <Route
            key={key}
            path={`${match.url}/${route.path}`}
            render={(props) =>
              route.meta && route.meta.permission === false ? (
                <Redirect
                  to={{
                    pathname: "/error/403",
                    state: { from: props.location },
                  }}
                />
              ) : (
                <route.component {...props} />
              )
            }
          />
        ))}
      </div>
      <div className="footer-container">
        <Typography variant="caption" style={{ fontSize: "14px" }}>
          Super Admin EV+ ©2024
        </Typography>
        <Typography variant="caption" style={{ fontSize: "14px" }}>
          Designed & Developed by{" "}
          <a
            href="https://www.accucia.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Accucia Softwares Pvt Ltd.
          </a>
        </Typography>
      </div>
    </HulkAppLayout>
  );
}

export default DefaultLayout;
