// ** Checks if an object is empty (returns boolean)
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import firebase from "@firebase/app";
import { NotificationManager } from "react-notifications";

export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const MySwal = withReactContent(Swal);
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: "login session has been expired ?",
    text: "login again to continue accessing your account !",
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Yes, logout it!",
    customClass: {
      confirmButton: "btn btn-danger",
      cancelButton: "btn btn-outline-danger ml-1",
    },
    allowOutsideClick: false,
    buttonsStyling: false,
  }).then(function (result) {
    if (result.value) {
      firebase.auth().signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/";
    }
  });
};
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase
    .auth()
    .currentUser.getIdToken(false)}`;
  return config;
});
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      return Promise.reject(error);
    }
  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export { Axios, AxiosInstance };



// upload image on s3 bucket  directly
// export const uploadImageToS3 = (file) => {
//   const params = {
//     Key: `${Date.now()}_${file.name}`,
//     Body: file,
//     ACL: 'public-read',
//     ContentType: file.type
//   };

//   return new Promise((resolve, reject) => {
//     s3.upload(params, (err, data) => {
//       if (err) {
//         resolve("");
//       } else {
//         resolve(data.Location);
//       }
//     });
//   });
// };



export const uploadImageToS3 = async (file) => {
  try {
    // Step 1: Request the presigned URL from the backend
    const response = await Axios.post(`/api/third-party-service/generate-presigned-url`, {
      filePath: file.name,
      fileType: file.type
    });

    if (response.data.status === false) {
      NotificationManager.error("Failed to upload file", "Error");

      return ""

      // throw new Error("Failed to generate presigned URL");
    } else {
      const presignedUrl = response.data.data;

      // Step 2: Upload the file to S3 using the presigned URL
      const uploadResponse = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type
        }
      });

      // Step 3: If upload is successful, return the file URL (without query parameters)
      if (uploadResponse.status === 200) {
        const fileUrl = presignedUrl.split('?')[0]; // Get the URL without query parameters
        // console.log("File uploaded successfully! File URL:", fileUrl);
        return fileUrl; // Return the file URL
      } else {
        NotificationManager.error("Failed to upload file", "Error");
        return ""
        // throw new Error("Failed to upload file");
      }
    }
  } catch (error) {
    NotificationManager.error("Failed to upload file", "Error");
    return ""
    // console.error("Error uploading file or generating presigned URL:", error);
    // throw error; // Propagate the error so the calling function can handle it
  }
};



export const deleteImageFromS3 = (filepath) => {
  // const params = {
  //   Key: filepath,
  // };

  return new Promise((resolve, reject) => {
    // s3.deleteObject(params, (err, data) => {
    //   if (err) {
    resolve("");
    // } else {
    //   resolve(data);
    // }
    // });
  });
};


export const UPLOAD_IMAGES = async (
  event,
  setMediaFile,
  setShowPreview,
  setLoading
) => {
  setLoading(true); // Set loading to true when starting the upload

  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    setLoading(false); // Set loading to false if the condition is not met
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    setLoading(false); // Set loading to false if the condition is not met
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (
    extension === "png" ||
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "pdf"
    // event.target.accept.includes("video/") // Allow video files
  ) {
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    for (const element of event.target.files) {
      const file = element;

      const isImage = ["png", "jpg", "jpeg"].includes(extension);
      if (isImage && file.size <= 1000000) {
        const filePath = `vendor/${data.subfolderName
          }/${date}/${uuidv4()}.${extension}`;

        try {
          // await s3
          //   .upload({
          //     Key: filePath,
          //     Body: file,
          //     ACL: "public-read",
          //   })
          //   .promise();
          // const fileUrl = `https://relocare.s3.ap-south-1.amazonaws.com/${filePath}`;

          const response = await Axios.post(`/api/third-party-service/generate-presigned-url`, {
            filePath: filePath,
            fileType: file.type
          });

          if (response.data.status === false) {
            NotificationManager.error("Failed to upload file", "Error");
          } else {
            const presignedUrl = response.data.data;

            // Step 2: Upload the file to S3 using the presigned URL
            const uploadResponse = await axios.put(presignedUrl, file, {
              headers: {
                'Content-Type': file.type
              }
            });

            // Step 3: If upload is successful, return the file URL (without query parameters)
            if (uploadResponse.status === 200) {
              const fileUrl = presignedUrl.split('?')[0]; // Get the URL without query parameters
              // console.log("File uploaded successfully! File URL:", fileUrl);
              // return fileUrl; // Return the file URL
              NotificationManager.success("File uploaded successfully", "Success");
              // You can use setState or some other state management mechanism to update UI
              setMediaFile(fileUrl);
              setShowPreview(true);
            } else {
              NotificationManager.error("Failed to upload file", "Error");
            }
          }

          // // You can use setState or some other state management mechanism to update UI
          // setMediaFile(fileUrl);
          // setShowPreview(true);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "File size needs to be less than or equal to 1mb",
          "File name: " + file.name
        );
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      "Only JPG, PNG, JPEG, PDF, or video file types are allowed",
      "Error"
    );
  }

  setLoading(false); // Set loading to false when the upload is complete
};
export const UPLOAD_VIDEOS = async (
  event,
  setMediaFile,
  setShowPreview,
  setLoading
) => {
  setLoading(true);
  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    setLoading(false);
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    setLoading(false);
    return;
  }

  // const extension = fileUploadPath
  //   .substring(fileUploadPath.lastIndexOf(".") + 1)
  //   .toLowerCase();

  if (event.target.accept.includes("video/")) {
    // const today = new Date();
    // const date =
    //   today.getDate() +
    //   "-" +
    //   (today.getMonth() + 1) +
    //   "-" +
    //   today.getFullYear();

    for (const element of event.target.files) {
      const file = element;

      const isVideo = event.target.accept.includes("video/");

      if (isVideo && file.size <= 51000000) {
        // const filePath = `vendor/${data.subfolderName}/${date}/${uuidv4()}.${extension}`;
        try {

          const response = await Axios.post(`/api/third-party-service/generate-presigned-url`, {
            filePath: file.name,
            fileType: file.type
          });

          if (response.data.status === false) {
            NotificationManager.error("Failed to upload file", "Error");
          } else {
            const presignedUrl = response.data.data;

            // Step 2: Upload the file to S3 using the presigned URL
            const uploadResponse = await axios.put(presignedUrl, file, {
              headers: {
                'Content-Type': file.type
              }
            });

            // Step 3: If upload is successful, return the file URL (without query parameters)
            if (uploadResponse.status === 200) {
              const fileUrl = presignedUrl.split('?')[0]; // Get the URL without query parameters
              // console.log("File uploaded successfully! File URL:", fileUrl);
              // return fileUrl; // Return the file URL
              NotificationManager.success("Video uploaded successfully", "Success");
              // You can use setState or some other state management mechanism to update UI
              setMediaFile(fileUrl);
              setShowPreview(true);
            } else {
              NotificationManager.error("Failed to upload file", "Error");
            }
          }

        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "Video size needs to be less than or equal to 50mb",
          "File name: " + file.name
        );
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error("Only video file types are allowed", "Error");
  }

  setLoading(false);
};


function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
